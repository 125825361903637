import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/room/*',
    name: 'Room',
    component: () => import('../views/Room.vue')
  },
  {
    path: '/bugs',
    name: 'Bugs',
    component: () => import('../views/Home.vue'),
    beforeEnter() {
      window.location.href = "https://trello.com/b/EutfX1aj/gluno";
    }
  },
  {
    path: '/ideas',
    name: 'Ideas',
    component: () => import('../views/Home.vue'),
    beforeEnter() {
      window.location.href = "https://trello.com/b/EutfX1aj/gluno";
    }
  },
  {
    path: '/*',
    redirect: '/'
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router