<template>
  <v-app id="inspire"  style="background: #000000">
    <v-main>
      <span v-if="version !== ''" style="font-family: Ubuntu; font-weight: bold; color: #FFFFFF; font-size: 50%; position: absolute; top: 0; left: 0;">{{ version }}</span>
      <span v-if="versionClient !== ''" style="font-family: Ubuntu; font-weight: bold; color: #FFFFFF; font-size: 50%; top: 0; left: 0;">{{ versionClient }}</span>
      <v-container style="margin-top: 10vh; left: 10vw; right: 10vw; width: 80vw; height: 80vh;">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import io from "socket.io-client";
  export default {
    data: () => ({
      socket: io("backend.gl-uno.com"),
      mobile: false,
      roomId: '',
      validRoom: false,
      version: 'Development Build v1.0.0 (20210923.1.0.0)',
      versionClient: 'Client Build v1.0.0 (20210923.1.0.0)'
    }),
    mounted: function(){
      var processData = this;
      setInterval(function(){
        processData.socket.emit("getData", {}, function (data) {
            if (data) {
                for (var key in data) {
                  processData[key] = data[key];
                }
            } else {
                console.log("No Data")
            }
        });
        processData.socket.emit("stillHere", { token: localStorage.getItem('userToken') }, function () {
        });
      }, 1000);
    }
  }
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Ubuntu');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  background-color: #000000
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}

a {
  all: unset;
}

.v-application a {
  color: #FFFFFF !important;
}
</style>